import ShoppingCartProductContext from "./ShoppingCartProductContext";
import React  from 'react';
import { useContext,useEffect,useState} from "react";
import {API_URL_IMAGE_ITEM} from "../apis/URL/constants"

const ProductCard = ({ product,openDetailsModal }) => {
  const { addToCart, increase, cartItems,decrease,removeFromCart } =
    useContext(ShoppingCartProductContext);
  //Check whether the product is in the cart or not
  const isInCart = (product) => {
    return !!cartItems.find((item) => item.id === product.id);
  };
  
  const getQuantity = (product) => {
    const cartItemsCopy = [...cartItems];
    const cartItem = cartItemsCopy.find((item) => item.id === product.id);
    return cartItem ? cartItem.quantity : 0;
  };

  useEffect(() => {
    if (getQuantity(product) < 1 && isInCart(product)) {
      removeFromCart(product);
    }
  }, [product, cartItems]);

    return (
      <article className="relative flex flex-col overflow-hidden rounded-lg border">
      <div className="aspect-square overflow-hidden" onClick={() => openDetailsModal(true,product)}>
        <img className="h-full w-full object-cover transition-all duration-300 group-hover:scale-125" src={API_URL_IMAGE_ITEM + product.images} alt="" />
      </div>
      {/* <div className="absolute top-0 m-2 rounded-full bg-white">
        <p className="rounded-full bg-[#6875f5] p-1 text-[8px] font-bold uppercase tracking-wide text-white sm:py-1 sm:px-3">Sale</p>
      </div> */}
      <div className="my-4 mx-auto flex w-10/12 flex-col items-start justify-between">
        <div className="mb-2 flex">
          <p className="mr-3 text-sm font-semibold">${product.price}</p>
          {/* <del className="text-xs text-gray-400">${product.priceSale} </del> */}
        </div>
        <h3 className="mb-2 text-sm text-gray-400 line-clamp-2 h-10">{product.name}</h3>
      </div>
   
      {!isInCart(product) ? (
       <button className="group mx-auto mt-auto mb-2 flex h-10 w-10/12 items-stretch overflow-hidden rounded-md text-gray-600"  onClick={() => addToCart(product)} >
       <div className="flex w-full items-center justify-center bg-gray-100 text-xs uppercase transition group-hover:bg-[#4353de] group-hover:text-white">Add</div>
        <div className="flex items-center justify-center bg-gray-200 px-5 transition group-hover:bg-[#6875f5] group-hover:text-white">+</div>
        </button>
         ) : ( 
         <div className="group mx-auto mt-auto mb-2 flex h-10 w-10/12 items-stretch overflow-hidden rounded-md text-gray-600" >
       <button className="flex-1 items-center justify-center bg-gray-200 px-5 transition group-hover:bg-[#6875f5] group-hover:text-white" onClick={()=> decrease(product)}>-</button>
       <div className="flex-1 flex items-center justify-center bg-gray-100 text-xs uppercase transition group-hover:bg-[#4353de] group-hover:text-white">{getQuantity(product)}</div>
       <button className="flex-1 items-center justify-center bg-gray-200 px-5 transition group-hover:bg-[#6875f5] group-hover:text-white" onClick={()=> increase(product)}>+</button>
      </div>
     
      
          )}
    
    </article>

        );
};

export default ProductCard;
import React from "react";
import {Link} from "react-router-dom"
import image from '../assets/img/landingPageBackground.jpg'

const HomePageImage = () => {
    return (
      // <div>
      //  <div className="flex body-font font-poppins justify-center">
      //   <div className="md:mt-2 bg-gray-50  items-center w-full md:w-9/12">
      //     <section className="h-full g-cover bg-center py-32" style={{backgroundImage: 'url("https://cdn.pixabay.com/photo/2022/01/28/12/17/delivery-6974508_1280.jpg")'}}>
      //       <div className="container mx-auto text-center text-black">
      //         <h1 className="text-5xl font-medium mb-6">Welcome to My Shop</h1>
      //         <p className="text-xl mb-12">Get your purchases delivered right to your doorstep with our fast and reliable delivery service. With Lebanon Online Mall, shopping has never been easier!</p>
      //         <Link to="/categories" className="bg-indigo-500 text-white py-4 px-12 rounded-full hover:bg-indigo-600" >Shop Now</Link>
      //       </div>
      //     </section>
      //   </div>
      // </div>
      // </div>
      <div className="relative mx-auto w-full max-w-6xl">
      <div className="sm:mt-10 py-48 sm:py-28 px-4 flex justify-center items-center bg-gradient-to-br from-gray-700 to-gray-900">

        {/* :IMAGE BACKGROUND */}
        <img src={image} alt="backgroud image" className="absolute inset-0 w-full h-full object-cover filter mix-blend-overlay" />


        {/* :PROMO */}
        <div className="relative max-w-2xl flex flex-col justify-center items-center text-center">
          {/* ::Title */}
          <h2 className="text-2xl sm:text-3xl md:text-4xl text-white font-extrabold tracking-wide">Welcome to Lebanon Online Mall, where shopping is made easy and convenient</h2>
          {/* ::Text */}
          <p className="mt-3 text-sm sm:text-base text-white font-medium">Get your purchases delivered right to your doorstep with our fast and reliable delivery service. With Lebanon Online Mall, shopping has never been easier!</p>
          {/* ::Button */}
          {/* <a href="#link" className="mt-6 py-2.5 px-6 shadow rounded bg-gray-50 text-sm sm:text-base text-gray-700 font-semibold hover:shadow-lg hover:bg-white hover:text-gray-900">Shop Now</a> */}
          <Link to="/categories" className="mt-6 py-2.5 px-6 shadow rounded bg-indigo-500 text-sm sm:text-base text-white font-semibold hover:bg-indigo-600  hover:text-gray-900" >Shop Now</Link>
        </div>

      </div>
    </div>
    );
  };
  
  export default HomePageImage;

import React , { useEffect, useState } from "react"
import '../assets/style/tailgrids-fallback.css'
import CategoryCard from "../components/CategoryCard";
import { CategoryAPI } from "../apis/CategoryAPI"

const Categories = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([])

  useEffect(() => {
    try {
      CategoryAPI.getAll().then((categories) => {
        setCategories(categories)
        setLoading(false);
      })
      
    } catch (error) {
      console.log(error);
    }

  }, [])

    return (
      <div>
{loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : ( <div>
      
        
         <div className="mx-auto max-w-md text-center ">
            <h2 className="pt-10 font-serif text-3xl font-bold sm:text-3xl text-gray-700">Categories</h2>
          </div>
       <div className="mx-auto lg:p-20 py-5 px-4 w-full max-w-md sm:max-w-2xl lg:max-w-7xl">
     
      <div className="grid  grid-cols-2 lg:grid-cols-5  gap-5">

        {/* :TITLE */}
       
        {categories.map((category) => (
          <CategoryCard key={category.id} category={category} />
        ))}
      </div>
    
    </div>
</div>
)}

      </div>
     
    );
  };
  
  export default Categories;
import React, { useState } from "react";
import { useContext } from "react";
import ShoppingCartProductContext from "../components/ShoppingCartProductContext";
import { sumItems } from "../components/ShoppingCartReducer";
import ProductCardCheckout from "../components/ProductCardCheckout";
import cod_logo from "../assets/img/cod-logo.png";
import ShippingDetailsForm from "../components/ShippingDetailsForm";
import { OrderAPI } from "../apis/OrderAPI";
import { Link } from "react-router-dom";
const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const [orderDone, setOrderDone] = useState(false);
  const { cartItems, checkout, clearCart } = useContext(
    ShoppingCartProductContext
  );
  const { itemCount, total } = sumItems(cartItems);

  const submitForm = (formData) => {
    if (formData.email === "") {
      delete formData.email;
    }
    const items = cartItems.map((item) => {
      return { id: item.id, quantity: item.quantity };
    });
    formData.items = items;
    try {
      OrderAPI.submit(formData).then((response) => {
        console.log(response);
        setLoading(false);
        setOrderDone(true);
        clearCart();
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div className="mx-auto py-5  w-full max-w-lg sm:max-w-2xl lg:max-w-7xl">
          {orderDone ? (
            <div>
              <div className="bg-white p-6  md:mx-auto">
                <svg
                  viewBox="0 0 24 24"
                  className="text-green-600 w-16 h-16 mx-auto my-6"
                >
                  <path
                    fill="currentColor"
                    d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                  ></path>
                </svg>
                <div className="text-center">
                  <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                    Order Done!
                  </h3>
                  <p className="text-gray-600 my-2">Thank you </p>
                  <p> Have a great day! </p>
                  <div className="py-10 text-center">
                    <Link
                      to="/categories"
                      className="px-12 rounded bg-indigo-700 hover:bg-indigo-600 text-white font-semibold py-3"
                    >
                      GO BACK
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
                <div className="px-4 pt-8">
                  <p className="text-xl font-medium">Order Summary</p>
                  <p className="text-gray-400">
                    Check your items. And select a suitable shipping method.
                  </p>
                  <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6">
                    {cartItems.map((product) => (
                      <ProductCardCheckout
                        key={product.id}
                        product={product}
                      ></ProductCardCheckout>
                    ))}

                    {itemCount < 1 && (
                      <p className="text-center opacity-50">
                        Your cart is empty
                      </p>
                    )}
                  </div>
                  <p className="mt-8 text-lg font-medium">Payment Methods</p>
                  <form className="mt-5 grid gap-6">
                    <div className="relative">
                      <input
                        className="peer hidden"
                        id="radio_1"
                        type="radio"
                        name="radio"
                        defaultChecked
                      />
                      <span className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white" />
                      <label
                        className="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4"
                        htmlFor="radio_1"
                      >
                        <img
                          className="w-14 object-contain"
                          src={cod_logo}
                          alt=""
                        />
                        <div className="ml-5">
                          <span className="mt-2 font-semibold">
                            Cash on Delivery
                          </span>
                        </div>
                      </label>
                    </div>
                  </form>
                </div>
                <ShippingDetailsForm
                  FormShippingDetails={submitForm}
                  loading={() => setLoading(true)}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Checkout;

import React  from 'react';
import { API_URL_IMAGE_ITEM } from "../apis/URL/constants";

const ProductCardCheckout = ({product}) => {

  return (

  <div className="flex rounded-lg bg-white flex-row">
          <img className="m-2 h-24 w-28 rounded-md border object-cover object-center" src={API_URL_IMAGE_ITEM + product.images} alt="" />
          <div className="flex w-full flex-col px-4 py-4">
            <span className="font-semibold">{product.name}</span>
            <span className="float-right text-gray-400">Qty {product.quantity}</span>
            <p className="mt-auto text-lg font-bold">${product.price}</p>
          </div>
        </div>
   
  );
};

export default ProductCardCheckout;
import React, { useEffect, useState } from "react";
import ShopCard from "../components/ShopCard";
import { ShopAPI } from "../apis/ShopAPI";
import { useParams } from "react-router-dom";
const ShopsInCategory = () => {
  const { idcategory } = useParams();
  const [loading, setLoading] = useState(true);
  const [shops, setShops] = useState([]);

  useEffect(() => {
    try {
      ShopAPI.get(idcategory).then((shops) => {
        setShops(shops);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <section className="bg-white  text-gray-700 mt-10">
          <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-md text-center">
              <h2 className="font-serif text-2xl font-bold sm:text-3xl">
                Shops in {shops.category.name}
              </h2>
            </div>
            <div className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-4 lg:mt-16">
              {shops.data.map((shop) => (
                <ShopCard key={shop.id} shop={shop} />
              ))}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default ShopsInCategory;

import React from 'react';
import ProductsGrid from '../components/ProductsGrid'
import { BottomScrollListener } from 'react-bottom-scroll-listener';

const Products = () => {

    return (
<ProductsGrid>
<BottomScrollListener  />
</ProductsGrid>
        );
    };
    
export default Products;
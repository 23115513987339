import React from 'react';
import {Link} from 'react-router-dom'
import { API_URL_IMAGE_SHOP } from "../apis/URL/constants";
const ShopCard = ({ shop }) => {
    return (
    <div>
 <Link to={"/shops/"+ shop.id} className="md:h-32 flex items-center text-center bg-gray-100 border border-gray-200 rounded-lg shadow flex-row max-w-xl hover:bg-indigo-100 ">
 <div>
  <img className="object-cover rounded-t-lg h-32 w-36 md:w-48 rounded-l-lg " src={API_URL_IMAGE_SHOP + shop.image} alt={shop.name} />
  </div>
  <div className="mx-auto flex flex-col items-center p-4 leading-normal text-center" >
    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white ">{shop.name}</h5>
    {/* <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p> */}
    {/* <p className="mb-3 font-normal text-gray-700 dark:text-gray-400"></p> */}

  </div>
</Link>
</div>
        );
    };
    
export default ShopCard;

import React from "react"
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";

const Layout = () => {
    return (
      <div>
        <div className="flex flex-col h-screen justify-between">
        <NavBar></NavBar>
        <Outlet></Outlet>
        <Footer></Footer>
</div>

      
     
     
      </div>
    );
  };
  
  export default Layout;
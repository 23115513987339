import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const ItemAPI = {

  getAll: async function (id,page,cancel = false) {
    const response = await api.request({
      url: `subcategories/${id}/items?page=${page}&per_page=12`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
    })
    return response.data;
  },
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `items/${id}`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })

    return response.data
  }
}

const cancelApiObject = defineCancelApiObject(ItemAPI)

import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const CategoryAPI = {

  getAll: async function (cancel = false) {
    const response = await api.request({
      url: "/categories",
      method: "GET",
      signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
    })
    return response.data
  }
}

const cancelApiObject = defineCancelApiObject(CategoryAPI)

import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const ShopAPI = {

  getAll: async function (cancel = false) {
    const response = await api.request({
      url: "/subcategories",
      method: "GET",
      signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
    })
    return response.data
  },
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/categories/${id}/subcategories`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })

    return response.data
  }
}

const cancelApiObject = defineCancelApiObject(ShopAPI)
import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const CityAPI = {

  getAll: async function (id,cancel = false) {
    const response = await api.request({
      url: `/cities`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
    })
    return response.data;
  }
  }


const cancelApiObject = defineCancelApiObject(CityAPI)
import React  from 'react';
import './App.css';
import Routers from './components/Routers'
import { useEffect } from 'react'
function App() {
  useEffect(() => {
    document.title = 'Lebanon Online Mall';
  });
  return (
    <div>
   <Routers />
   </div>
  );
}

export default App;

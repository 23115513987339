import React from 'react';
import {Link } from "react-router-dom";
const NoPage = () => {
  return (
    <div>
      <div className="px-4 bg-white place-content-center">
  <div className="text-center">
    <h1 className="font-black text-gray-200 text-9xl">404</h1>

    <p className="mt-4 text-gray-500">We can't find that page.</p>

   <Link to='/'  className="inline-block px-5 py-3 mt-6 text-sm font-medium text-white bg-indigo-600 rounded hover:bg-indigo-700 focus:outline-none focus:ring">
   Go Back Home

   </Link>
    {/* <a
      href="#"
      className="inline-block px-5 py-3 mt-6 text-sm font-medium text-white bg-indigo-600 rounded hover:bg-indigo-700 focus:outline-none focus:ring"
    >
      Go Back Home
    </a> */}
  </div>
</div>

    </div>
  );
};

export default NoPage;

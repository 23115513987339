import React, { useEffect, useState, useContext } from "react";
import { CityAPI } from "../apis/CityAPI";
import lebanonflag from "../assets/img/lebanonflag.png";
import ShoppingCartProductContext from "../components/ShoppingCartProductContext";
import { sumItems } from "../components/ShoppingCartReducer";

function ShippingDetailsForm(props) {
  const [loading, setLoading] = useState(false);
  const { cartItems } = useContext(ShoppingCartProductContext);
  const { itemCount, total } = sumItems(cartItems);
  const [cities, setCity] = useState([]);
  const [prixLivraison, setPrixLivraison] = useState("0.00");
  const [formData, setFormData] = useState({
    full_name: "",
    address: "",
    email: "",
    phone_number: "",
    id_city: "",
    items: null,
  });
  useEffect(() => {
    try {
      CityAPI.getAll().then((cities) => {
        setCity(cities);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const [phoneNumberErrorMessage, setPhoneNumberErrorMessageErrorMessage] =
    useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [fullNameErrorMessage, setFullNameErrorMessage] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");

  const handleChange = (event) => {
    const selectedCityId = parseInt(event.target.value);
    const selectedCity = cities.find((city) => city.id === selectedCityId);
    if (selectedCity) {
      setFormData({ ...formData, id_city: selectedCityId });
      setPrixLivraison(selectedCity.cost);
    } else {
      setFormData({ ...formData, id_city: "" });
      setPrixLivraison("0.00");
    }
  };

  function containsOnlyDigits(str) {
    const regex = /^\d+$/;
    return regex.test(str);
  }
  function isValidLebaneseNumber(str) {
    const regex = /^(03|76|70|71|78|79|81)\d{6}$/;
    return regex.test(str);
  }
  function containsOnlyLetters(str) {
    const regex = /^[a-zA-Z\s]{3,30}$/;
    return regex.test(str);
  }
  function isValidEmail(str) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(str);
  }
  function isValidAddress(str) {
    const regex = /^[a-zA-Z0-9.\-_ ]{3,120}$/;
    return regex.test(str);
  }
  const validateForm = () => {
    setPhoneNumberErrorMessageErrorMessage("");
    setEmailErrorMessage("");
    setFullNameErrorMessage("");
    setAddressErrorMessage("");

    if (!isValidEmail(formData.email) && formData.email.length > 0) {
      setEmailErrorMessage(
        "Please provide a valid email address or keep it empty"
      );
      return;
    }
    // if (!containsOnlyLetters(formData.full_name)) {
    //   setFullNameErrorMessage("Please provide a valid full name");
    //   return;
    // }

    if (!isValidLebaneseNumber(formData.phone_number)) {
      setPhoneNumberErrorMessageErrorMessage(
        "Please provide a valid phone number"
      );
      return;
    }
    // if (!isValidAddress(formData.address)) {
    //   setAddressErrorMessage("Please provide a valid address");
    //   return;
    // }
    if (!containsOnlyDigits(formData.id_city)) {
      setAddressErrorMessage("Please provide a valid address");
      return;
    }
    props.loading(true);
    props.FormShippingDetails(formData);
  };

  return (
    <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
      <p className="text-xl font-medium">Shipping Details</p>
      <p className="text-gray-400">
        Complete your order by providing your shipping details.
      </p>
      <div className>
        <label htmlFor="email" className="mt-4 mb-2 block text-sm font-medium">
          Email{"  "}
          <span className="text-red-600 text-xs ">{emailErrorMessage}</span>
        </label>
        <div className="relative">
          <input
            type="text"
            id="email"
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            name="email"
            className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            placeholder="your.email@gmail.com"
          />
          <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
              />
            </svg>
          </div>
        </div>
        <label
          htmlFor="card-holder"
          className="mt-4 mb-2 block text-sm font-medium"
        >
          Full name*{"  "}
          <span className="text-red-600 text-xs ">{fullNameErrorMessage}</span>
        </label>
        <div className="relative">
          <input
            type="text"
            onChange={(e) =>
              setFormData({ ...formData, full_name: e.target.value })
            }
            id="card-holder"
            name="card-holder"
            className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            placeholder="Your full name here"
          />
          <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
              />
            </svg>
          </div>
        </div>
        <div>
          <label
            htmlFor="phone-number"
            className="mb-2 mt-4 block text-sm font-medium"
          >
            Phone Number*{"  "}
            <span className="text-red-600 text-xs ">
              {phoneNumberErrorMessage}
            </span>
          </label>
          <div className="relative">
            <input
              type="text"
              id="phone-number"
              onChange={(e) =>
                setFormData({ ...formData, phone_number: e.target.value })
              }
              name="phone-number"
              className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder={"ex: 71162933"}
            />
            <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
            </div>
          </div>
        </div>

        <label
          htmlFor="billing-address"
          className="mt-4 mb-2 block text-sm font-medium"
        >
          Address*{"  "}
          <span className="text-red-600 text-xs ">{addressErrorMessage}</span>
        </label>
        <div className="flex flex-col sm:flex-row">
          <div className="relative flex-shrink-0 sm:w-7/12">
            <input
              type="text"
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              id="billing-address"
              name="billing-address"
              className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="Street Address"
            />
            <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <img
                className="h-4 w-4 object-contain"
                src={lebanonflag}
                alt=""
              />
            </div>
          </div>
          <select
            type="text"
            name="billing-state"
            className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            value={formData.id_city}
            onChange={handleChange}
          >
            <option>Select City</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-6 border-t border-b py-2">
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900">Subtotal</p>
            <p className="font-semibold text-gray-900">${total}</p>
          </div>
          <div className="flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900">Shipping</p>
            <p className="font-semibold text-gray-900">${prixLivraison}</p>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-between">
          <p className="text-sm font-medium text-gray-900">Total</p>
          <p className="text-2xl font-semibold text-gray-900">
            ${(parseFloat(total) + parseFloat(prixLivraison)).toFixed(2)}
          </p>
        </div>
      </div>
      {itemCount >= 1 ? (
        <button
          onClick={validateForm}
          className="mt-4 mb-8 w-full rounded-md bg-indigo-600 px-6 py-3 font-medium text-white hover:bg-indigo-700"
        >
          Place Order
        </button>
      ) : (
        <button
          disabled
          className="mt-4 mb-8 w-full rounded-md  px-6 py-3 font-medium text-white bg-gray-200"
        >
          Place Order
        </button>
      )}
    </div>
  );
}

export default ShippingDetailsForm;


import React from "react"
import HomePageImage from "../components/HomePageImage";

const Home = () => {

    return (
      <div>
    <HomePageImage></HomePageImage>
      </div>
      
    );
  };
 
 
  export default Home;
  
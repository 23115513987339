
import React from "react";
import '../assets/style/tailgrids-fallback.css'
import {Link} from 'react-router-dom'
import { API_URL_IMAGE_CATEGORY } from "../apis/URL/constants";

const CategoryCard = ({ category }) => {
    return (
      <div className="h-72 order-5 lg:row-span-2 col-span-full sm:col-span-1 relative shadow rounded-md overflow-hidden bg-blue-800 filter hover:shadow-lg hover:brightness-125">
      <Link to={"/categories/"+ category.id}  className="py-5 px-5 block w-full h-full">
        {/* ::Background Picture */}
        <div>
          {/* :::picture */}
          <img src={API_URL_IMAGE_CATEGORY + category.image} alt={category.name} className="absolute inset-0 w-full h-full object-cover object-center" />
          {/* :::overlay */}
          <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-blue-700 opacity-50" />
        </div>
        {/* ::Category Infos */}
        <div className="relative h-full flex flex-col justify-between items-start space-y-10 text-white">
          {/* :::badge tag */}
          {/* <span className="inline-flex justify-center items-center py-1 px-3 border-none rounded bg-white bg-opacity-30 text-xs text-white font-semibold">Popular</span> */}
          {/* :::name */}
          <h3 className="text-3xl font-playfair tracking-wider leading-relaxed antialiased">
            <span className="block">{category.name}</span>
            {/* <span className="block">Collection</span> */}
          </h3>
        </div>
      </Link>
    </div>

    


   
    );
  };
  
  export default CategoryCard;
import React ,{ useEffect, useState } from 'react';
import NoPage from "../pages/NoPage";
import ProductCard from "./ProductCard";
import { useParams } from "react-router-dom";
import { ItemAPI } from "../apis/ItemAPI"

const ProductsGrid = () => {
  const { idshop } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [shop, setShop] = useState('');
  const [page, setPage] = useState(1);
  const [showDetailsModal,setShowDetailsModal] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(false);
  const [showDetailsModalProduct,setShowDetailsModalProduct] = useState(null);
  useEffect(() => {
    const handleScroll = () => {
      const offsetHeight = document.documentElement.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollTop = document.documentElement.scrollTop;

      const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 10;

      setReachedBottom(hasReachedBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const fetchProducts = async () => {
   
  try {
    ItemAPI.getAll(idshop,page).then((items) => {
      setShop(items.subcategory);
      if(products.length < items.data.total){
    
      const getProducts = items.data.data;
      setProducts((prevProducts) => {
        const existingIds = new Set(prevProducts.map(product => product.id));
        const newProducts = getProducts.filter(product => !existingIds.has(product.id));
        return [...prevProducts, ...newProducts];
      });

      setLoading(false);
      if(items.data.current_page < items.data.last_page){
        setPage(page + 1);
        setReachedBottom(false);
      }else{
        setLoadingProducts(false);
      }
    }else{
      setLoadingProducts(false);
      setLoading(false);
    }
    })
    
  } catch (error) {
    console.log(error);
  }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    console.log(reachedBottom);
    if(reachedBottom){
      setLoadingProducts(true);
       setTimeout(() => {
        fetchProducts();
       }, 1000);
  }
}, [reachedBottom]);

const HandleOpenDetailsModal = (show,product)=> {
  console.log("clicked");
  setShowDetailsModal(show);
  setShowDetailsModalProduct(product);
}

  if(/^\d+$/.test(idshop)){

    return (
      <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : ( <div >
<section className="bg-white py-12 text-gray-700 sm:py-16 lg:py-20">
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-md text-center">
            <h2 className="font-serif text-2xl font-bold sm:text-3xl">{shop}</h2>
          </div>
          <div className="mt-10 grid grid-cols-2 gap-6 sm:grid-cols-6 sm:gap-4 lg:mt-16">
          {products.map((product) => (
          <ProductCard key={product.id} product={product} openDetailsModal={HandleOpenDetailsModal} />
        ))}
          </div>

        </div>
        {loadingProducts &&  <div className="flex mt-10 justify-center items-center ">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>}
      </section>
     
      {showDetailsModal ? (
        <>
        {console.log(showDetailsModalProduct)}
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative  my-6 mx-auto max-w-3xl w-10/12 sm:w-4/12">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                   {showDetailsModalProduct.name}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowDetailsModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto min-w-full">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                        {showDetailsModalProduct.description}
                    </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  {/* <button
                    className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowDetailsModal(false)}
                  >
                    Cancel
                  </button> */}
                  <button
                    className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowDetailsModal(false)}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
       </div>
      )}
      
      </div>
); 
}
return <NoPage></NoPage>
};

export default ProductsGrid;

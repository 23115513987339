import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../pages/Home';
import NoPage from '../pages/NoPage';
import Contact from '../pages/Contact';
import Layout from './Layout';
import Categories from '../pages/Categories';
import Products from '../pages/Products';
import Shops from '../pages/Shops';
import Checkout from '../pages/Checkout';
import ShopsInCategory from '../pages/ShopsInCategory';
class Routers extends React.Component {
    render() {
      return (
        <BrowserRouter>
        <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="categories" element={<Categories />} />
          <Route path="shops" element={<Shops />} />
          <Route path="shops/:idshop" element={<Products />} />
          <Route path="categories/:idcategory" element={<ShopsInCategory />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      
    </BrowserRouter>
      );
    }
  }
  export default Routers